const CACHE = "aqumen-qwa-2022-08";
const PRE_CACHE_FILES = [
  "index.html",
  "index.js"
];

self.addEventListener("install", (event) => {
  console.debug("PWA Service Worker: Installing.");
  const caching = caches.open(CACHE).then((c) => c.addAll(PRE_CACHE_FILES));
  event.waitUntil(caching.then(() => self.skipWaiting()));
});

self.addEventListener("activate", (event) => {
  console.debug("PWA Service Worker: Activating.");
  event.waitUntil(
    caches.keys().then((keys) => {
      return Promise.all(keys.map((key) => {
        return (CACHE !== key) ? caches.delete(key) : true;
      }));
    })
  );
  return self.clients.claim();
});

self.addEventListener("fetch", (event) => {
  if (event.request.method !== "GET" || !event.request.url) {
    return;
  }
  if (!/\.(svg|png|js|html)$/.test(event.request.url)) {
    return;
  }
  if (/local/.test(event.request.url)) {
    return;
  }
  console.debug("PWA Service Worker: using cache.", event.request.url);
  event.respondWith(
    caches.match(event.request).then((cached) => {
      return cached || fetch(event.request).then((fetched) => {
        console.debug("PWA Service Worker: cache miss.", event.request.url);
        return caches.open(CACHE).then((cache) => {
          cache.put(event.request, fetched.clone());
          console.debug("PWA Service Worker: cached new.", event.request.url);
          return fetched;
        });
      });
    })
  );
});
